// import { configureStore } from '@reduxjs/toolkit'
// import logger from 'redux-logger';
// import rootReducer from './reducers'

// const store = configureStore({ reducer: rootReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger), })
// // The store now has redux-thunk added and the Redux DevTools Extension is turned on
// export default store;

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
});
export type AppDispatch = typeof store.dispatch

export default store;
