// src/components/Header.tsx
import React from 'react';
import { Typography } from '@mui/material';


const Products = () => {
  return (
    <>
        <Typography variant="h4">Products</Typography>
    </>
    
  );
};

export default Products;
