// utils.ts
export const formatCurrency = (value: number, currency: string = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(value);
};

export const formatDate = (value: string, format: string = 'yyyy-MM-dd') => {
    return new Date().toDateString();
};


export const formatValue = (value: any, type: string, format?:  string, defaultFormat?: string, defaultValue?: any) : any => {
  if(value === null && defaultValue === null) {
    return value;
  }
  switch (type) {
    case 'currency': return formatCurrency(value || defaultValue, format || defaultFormat);
    case 'date': return formatDate(value || defaultValue, format || defaultFormat);
    default: return value || defaultValue;
  }
}