export function Label({
    componentName,
  }: {
    componentName: string;
  }) {
    const content = (
      <span>
        <strong>{componentName}</strong>
      </span>
    );
    
  
    return content;
}