
// // reducer.ts
// import { combineReducers } from 'redux';
// // import { fetchInventoryData } from '../actions';
// import { SET_THEME } from '../actionTypes';

// const preloadedState = {
//   inventoryData: [],
//   theme: 'dark',
//   dashboardTiles: { 
//     '50kg': [{key: 'empty', value: 155, color: '#ed6c02'}, {key: 'filled', value: 20, color: '#2e7d32'}, {key: 'defect', value: 25, color: '#9c27b0'}],
    
//   }
// }

// const inventoryDataReducer = (state = [], action: { type: string, payload: any }) => {
//   switch (action.type) {
//     case 'data/fetchInventoryData/pending':
//       // Handle pending action
//       return state;
//     case 'data/fetchInventoryData/fulfilled':
//       // Handle fulfilled action
//       return action.payload;
//     case 'data/fetchInventoryData/rejected':
//       // Handle rejected action
//       return state;
//     default:
//       return state;
//   }
// };



// export interface RootState {
//   theme: string;
//   inventoryData: Array<any>;
// }

// const rootReducer = combineReducers({
//   inventoryData: inventoryDataReducer,
//   theme: themeReducer,
// });

// export default rootReducer;

import { combineReducers } from 'redux';
import dataReducer from '../actions';
import { SET_THEME } from '../actionTypes';

const themeReducer =  (state = 'dark', action : any) => {
  switch (action.type) {
    case SET_THEME:
      return action.payload;
    default:
      return state;
  }
}
const rootReducer = combineReducers({
  data: dataReducer,
  theme: themeReducer,
});

export default rootReducer;