

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { formatDate } from '../../utils';
import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DonutGraph from './DonutGraph';
import { SeriesItemProps } from '../../interfaces';

interface Props {
    name: string;
    subtitle: string|number;
    donutData: Array<SeriesItemProps>;
    description: string;
    updated_at?: string
}


const Tile = (props : Props) => {
  const { name, subtitle, donutData, description, updated_at } = props;
  
  return (
    <Card sx={{paddingX: '0.5rem', paddingY: '1rem'}}>
        <Box sx={{ display: 'flex', flexDirection: 'row', paddingX: '1rem', justifyContent: 'space-between' }}>
            

            <Typography variant="h5">{name}</Typography>
            <Tooltip title={description}>
                <IconButton>
                    <InfoOutlinedIcon fontSize='small'/>
                </IconButton>
            </Tooltip>
        </Box>
        <Divider variant="middle" sx={{marginY : '0.5rem', color: '#707070'}} />
        <Grid container  >
            <Grid xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                <DonutGraph title={`${subtitle}`} dataArray={donutData}/>
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{display: 'flex', justifyContent: 'space-between', paddingX: '1rem'}}>
            <Grid xs={6}>
                <Typography variant="caption">Last updated: {formatDate(updated_at || '')}</Typography>
            </Grid>
            <Grid xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                <IconButton aria-label="refresh" size="small">
                    <CachedOutlinedIcon fontSize="inherit" />
                </IconButton>
            </Grid>
        </Grid>
    </Card>
  );
}

export default Tile;