import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Tile from '../shared/Tile';

const Dashboard = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ padding: '1rem', display: 'flex', flexDirection: 'row'}}>
            <Grid  xs={12} sm={6} md={4}>
                <Tile {...{
                    name: "50kg",
                    color: 'secondary',
                    subtitle: 200,
                    description: 'testing',
                    donutData: []//implement that the tile fetches the data itself not the dashboard
                }}/>
            </Grid>
            <Grid  xs={12} sm={6} md={4}>
                <Tile {...{
                    name: "11kg-Pol",
                    color: 'info',
                    subtitle: 460,
                    description: 'polvalve',
                    donutData: []
                }}/>
            </Grid>
            <Grid  xs={12} sm={6} md={4}>
                <Tile {...{
                    name: "11kg-Snap",
                    color: 'warning',
                    subtitle: 500,
                    description: 'snap-on',
                    donutData: []
                }}/>
            </Grid>
            <Grid xs={6} md={8}>

            </Grid>
        </Grid>
    </Box>
  );
};

export default Dashboard;
