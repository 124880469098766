import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Label } from './Label';
interface Props {
  isDatePickerRange: boolean;
}
const DatePick: React.FC<Props> = ({ isDatePickerRange }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{paddingY: '1rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} columnGap={2} rowGap={2}>
             <Stack rowGap={1}>
                <Label componentName={isDatePickerRange ? 'Start date' : 'Date'}/>
                <DatePicker />
              </Stack>
          {isDatePickerRange && (<Stack rowGap={1}>
              <Label componentName="End date"/>
              <DatePicker />
            </Stack>)}
        </Box>
      </LocalizationProvider>
    </Box>
    
  );
};

export default DatePick;
