import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Label } from './Label';
import Stack from '@mui/material/Stack';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Main',
  'Oroquieta',
  'Calamba',
  'Sapang Dalaga',
  'Dapitan',
];

function getStyles(name: string, list: readonly string[], theme: Theme) {
  return {
    fontWeight:
      list.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Props {
  isMultiple: boolean
}
const CheckSelect : React.FC<Props> = ({ isMultiple = false}) => {
  const theme = useTheme();
  const [list, setList] = React.useState<string[]>([names[0]]);

  const handleChange = (event: SelectChangeEvent<typeof list>) : any => {
    const {
      target: { value },
    } = event;
    setList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Stack>
      <Label componentName="Location" />
      {/* <FormControl sx={{ mt: 0.8, width: 300, padding:0 }}> */}
        
        <Select
          sx={{ mt: 0.9, width: 300, padding: 0 }}
          multiple={isMultiple}
          value={list}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" sx={{ padding: '12px 14px !important'}} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 0.5, columnGap: 0.5, paddingY: 0 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, list, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      {/* </FormControl> */}
    </Stack>
  );
}

export default CheckSelect;