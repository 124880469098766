import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Products from './components/Products';
import Inventory from './components/Inventory';
import { PaletteMode } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './components/Header';
import { RootState } from './interfaces';
import { useSelector } from 'react-redux';
import { setTheme } from './store/actions'; 
import store from './store';

const App = () => {
  const themeColor = useSelector((state: RootState) => state.theme);

  const appliedTheme = createTheme({
    palette: {
      mode: themeColor as PaletteMode
    }
  });

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const newThemeColor = store.getState().theme;
      if (newThemeColor !== themeColor) {
        setTheme(newThemeColor); // Dispatch the setTheme action
      }
    });

    return () => {
      unsubscribe();
    };
  }, [themeColor]);
  
  return (
    <ThemeProvider theme={appliedTheme}>
      <main className="container" style={{ backgroundColor: appliedTheme.palette.background.default }}>
        <Router>  
          <Header company='RPM1'   />
        
          <Routes >
            <Route path="/" element={<Dashboard/>} />
            <Route path="/products" element={<Products/>} />
            <Route path="/inventory" element={<Inventory/>} />
          </Routes>
        </Router>
      </main>
    </ThemeProvider>
  );
};

export default App;
