import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  SET_THEME,
  SET_INVENTORY_DATA
} from './actionTypes';
import { InventoryDataProps } from '../interfaces';
const URL = process.env.REACT_APP_BASE_URL;


export const setTheme = (theme: string) => ({
  type: SET_THEME,
  payload: theme,
});

export const setInventoryData = (data: Array<InventoryDataProps>) => ({
  type: SET_INVENTORY_DATA,
  payload: data,
});

export const fetchInventoryData = createAsyncThunk('data/inventoryData', async () => {
  try {
    const response = await fetch(`${URL}/inventory/api/all`);
    
    const data = await response.json();
    return data.data;
  } catch (error) {
    // Handle error
    throw new Error('Failed to fetch data');
  }
});



const dataReducer = createSlice({
  name: 'data',
  initialState: {
    inventoryData: [] as Array<InventoryDataProps>,
    theme: 'dark',
    loading: false,
    error: null as string|null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryData.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state
      })
      .addCase(fetchInventoryData.fulfilled, (state, action) => {
        state.loading = false;
        state.inventoryData = action.payload;
      })
      .addCase(fetchInventoryData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message ?? null;
      });
  }
});

export default dataReducer.reducer;