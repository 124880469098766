

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SeriesItemProps } from '../../interfaces';

interface Props {
    title: string;
    dataArray: Array<SeriesItemProps>;
    colors?: Array<string>;
}

const options = {
    credits: { enabled : false},
    title: null,
    chart: {
        type: 'pie',
        height: 200, // Set the height of the plot area
        width: 200,
    },
    legend: { enabled: false},
    subtitle: {
        useHTML: true,
        floating: false,
        verticalAlign: 'middle',
        y: 10,
    },
    tooltip: {
        valueDecimals: 2,
        valueSuffix: ' TWh'
    },
    plotOptions: {
        series: {
            size: '100%',
            innerSize: '70%',
            dataLabels: {
                enabled: true,
                crop: false,
                distance: '-10%',
                style: {
                    fontWeight: 'normal',
                    fontSize: '10px'
                },
                connectorWidth: 0
            }
        }
    }
  };

const DonutGraph = (props : Props) => {
    const { title, dataArray, colors } = props;

    

    return (
    <HighchartsReact highcharts={Highcharts} options={{
        ...options,
        chart: {
            ...options.chart,
            backgroundColor: null,
        },
        subtitle: {
            ...options.subtitle,
            text: `<span style="font-size: 35px;">${title}</span>`,
        },
        series: [{ data: dataArray, name: title, shadow: {
            color: 'yellow',
            width: 10,
            offsetX: 0,
            offsetY: 0
        }}],
        ...(colors ? {colors} : {})
    }} />
    );
}

export default DonutGraph;