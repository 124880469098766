import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ThemeSwitch from './shared/ThemeSwitch';
import routes from '../routes';
import { Stack } from '@mui/material';
interface Props {
    company: string;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

const Header = (props: Props) => {
    const { company } = props;

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const navigate = useNavigate();

    const handleClick = (path: string) => {
        navigate(path);
    };
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Stack direction={'row'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} rowGap={1}>
                <img src="/rpm1logodark.png" alt="Logo" width={40} height={40}/>
                <Typography variant="h4" sx={{ my: 2 }}>
                    {company}
                </Typography>
            </Stack>
            <Divider />
            <List>
                {routes.map((item : any) => (
                <ListItem key={item.title} disablePadding>
                    <ListItemButton sx={{ textAlign: 'left' } } onClick={() => handleClick(item.path)}>
                        <IconButton>
                            <item.icon></item.icon>
                        </IconButton>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', marginBottom: '3.8rem' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src="/rpm1logodark.png" alt="Logo" width={40} height={40} style={{'marginRight': '8px'}}/>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        {props.company}
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {routes.map((item : any) => (
                            <Button onClick={() => handleClick(item.path)} key={item.title} sx={{ color: '#fff' }}>
                                <item.icon/>&nbsp;{item.title}
                            </Button>
                        ))}
                    </Box>
                   <ThemeSwitch />
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default Header;