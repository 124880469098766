import React from 'react';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DatePick from './DatePick';
import CheckSelect from './CheckSelect';

interface Props {
  routeName: string;
  hasDatePicker?: boolean;
  isDatePickerRange?: boolean;
  hasLocation?: boolean;
  isMultipleLocation?: boolean;
}
const Filters: React.FC<Props> = ({ routeName, hasDatePicker = true, isDatePickerRange = true, hasLocation = true, isMultipleLocation = true}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap',  padding: '3rem'}} boxShadow={5}>
      <Stack>
        <Typography variant="h5" sx={{paddingBottom: '1rem'}}>{routeName}</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', direction: 'row', alignItems: 'baseline'}} gap={2}>
            {hasLocation && (<CheckSelect isMultiple={isMultipleLocation}/>)}
            {hasDatePicker && (<DatePick isDatePickerRange={isDatePickerRange}/>)}
            
        </Box>
        
      </Stack>
    </Box>
    
  );
};

export default Filters;
