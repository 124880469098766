// import { RouteProps } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Products from './components/Products';
import Inventory from './components/Inventory';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
// import { ReactNode } from 'react';
// import { IconTypeMap } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
// Define the type for your route object
interface Route {
    path: string;
    component: () => JSX.Element;
    title: string,
    icon: SvgIconComponent,
}

// Define your routes as an array of RouteObjects
const routes: Route[] = [
  {
    path: '/',
    component: Dashboard,
    title: 'Dashboard',
    icon: GridViewOutlinedIcon,
  },
  {
    path: '/products',
    component: Products,
    title: 'Products',
    icon: CategoryOutlinedIcon,
  },
  {
    path: '/inventory',
    component: Inventory,
    title: 'Inventory',
    icon: InventoryOutlinedIcon,
  },
  // Add more routes as needed
];

export default routes;
