// src/components/Header.tsx
import React, { useEffect } from 'react';
import Filters from '../shared/Filters';
import EnhancedTable from '../shared/EnhancedTable';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInventoryData } from '../../store/actions';
import { AppDispatch } from '../../store';
import { RootState } from '../../interfaces';


const Inventory = () => {

  const dispatch = useDispatch<AppDispatch>();
  const { inventoryData, loading, error } = useSelector((state : RootState) => state.data);
  
  useEffect(() => {
    dispatch(fetchInventoryData());
  }, [dispatch]);

  if (loading) {
    console.log({loading, inventoryData})
  } else {
    console.log('not loading anymore', {inventoryData})
  }

  if (error) {
    console.log({error, inventoryData})
  }

  return (
    <>
      <Filters routeName="Inventory" hasDatePicker={true} isDatePickerRange={true} isMultipleLocation={true} />
      <Box sx={{ padding: '2rem'}}>
        <EnhancedTable loading={loading} rows={inventoryData || []} fields={[
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'empty',
    numeric: true,
    disablePadding: false,
    label: 'Empty',
  },
  {
    id: 'filled',
    numeric: true,
    disablePadding: false,
    label: 'Filled',
  },
  {
    id: 'defect',
    numeric: true,
    disablePadding: false,
    label: 'Defect',
  },
  {
    id: 'total',
    numeric: true,
    disablePadding: false,
    label: 'Total',
  },
]} title={'Inventory'} />
      </Box>
      
    </>
    
  );
};

export default Inventory;
